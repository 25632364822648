/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import { useAuth0 } from "@auth0/auth0-react";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterListOffRoundedIcon from "@mui/icons-material/FilterListOffRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";

import {
  Autocomplete,
  autocompleteClasses,
  Box,
  createTheme,
  Grid,
  Menu,
  MenuItem,
  Popper,
  TextField,
  Typography,
  useMediaQuery
} from "@mui/material";
import { styled, ThemeProvider, useTheme } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import { colors } from "common/scss/themes";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import {
  setFilters,
  setSaveFiltersModal,
  setViewSavedFiltersModal
} from "services/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "stateManagement/hooks";
import Badge from "../Badge";
import Button from "../Button";
import Download from "../Download";
import SearchInput from "../FormComponents/SearchInput";
import IconButton from "../IconButton";
import { IconArrowRight, IconFilter } from "../Icons/Icons";

const sortFilterOptions = [
  {
    name: "Overall",
    value: "distance"
  },
  {
    name: "Location",
    value: "location_score"
  },
  {
    name: "JD year",
    value: "jd_year_score"
  },
  {
    name: "Specialties/Skills",
    value: "specialty_score"
  },
  {
    name: "School",
    value: "school_score"
  },
  {
    name: "Current Firm",
    value: "work_history_score"
  }
];

interface FilterObjectType {
  [key: string]: any;
}

const themePagination = createTheme({
  typography: {
    fontFamily: "Noto Sans, sans-serif"
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "Noto Sans, sans-serif !important",
          fontWeight: 500,
          fontSize: "0.75rem",
          lineHeight: "1rem"
        }
      }
    }
  }
});

export interface Props {
  label?: string;
  numberOfResults: number;
  currentPage: number;
  rowsPerPage: number;
  changePage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  rowsPerPageOptions?: number[];
  withFilters?: boolean;
  withSearch?: boolean;
  withPadding?: boolean;
  toggleFiltersVisible?: () => void;
  filtersVisible?: boolean;
  download?: boolean;
  dataTable?: any;
  transform?: (data: any[]) => any[];
  tableRef?: any;
  shareButton?: boolean;
  shareAction?: () => void;
  optionsDropdown?: any[];
  filtersDropdown?: object;
  setFiltersDropdown?: (filters: any) => void;
  showPagination?: boolean;
  loading?: boolean;
  saveFilters?: boolean;
  filters?: any;
  sortingFilters?: boolean;
}

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  };

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1].label}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (Object.prototype.hasOwnProperty.call(child, "group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: any) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0
    }
  }
});
function ResultsBar({
  label = "Search Results",
  numberOfResults,
  currentPage,
  changePage,
  setRowsPerPage,
  rowsPerPageOptions = [10, 25, 50, 100],
  withFilters = false,
  withSearch = true,
  withPadding = false,
  toggleFiltersVisible = () => {
    /*  */
  },
  filtersVisible = false,
  rowsPerPage,
  download = false,
  dataTable = [],
  transform = (data: any[]) => {
    return data;
  },
  tableRef = null,
  shareButton = false,
  shareAction = () => {
    /*  */
  },
  optionsDropdown = [],
  filtersDropdown = {},
  loading = false,
  setFiltersDropdown = () => {
    /*  */
  },
  showPagination = true,
  saveFilters = false,
  filters = {},
  sortingFilters = false
}: Props) {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth0();
  const [valueOffice, setValueOffice] = useState([]);
  const filtersRedux = useAppSelector((state) => state.filters.filters);
  const { canDownloadReports } = useAppSelector((state) => state.permissions);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const addFiltersData = (key: string, data: number[]) => {
    const obj: FilterObjectType = filtersDropdown;
    if (data.length > 0) {
      obj[key] = data;
    } else {
      delete obj[key];
    }
    setFiltersDropdown({ ...obj });
    dispatch(setFilters({ ...obj }));
  };

  useEffect(() => {
    if (!_.isEmpty(valueOffice)) {
      const value = valueOffice.map((item: any) => item.value);
      addFiltersData("locations", value);
    } else {
      addFiltersData("locations", []);
    }
  }, [valueOffice]);

  const changeInnerPage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const table = tableRef;
    if (table) {
      table.current.scrollTop = 0;
    }

    changePage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    changePage(1);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const [anchorElFiltersMenu, setAnchorElFiltersMenu] =
    useState<null | HTMLElement>(null);

  const openFiltersMenu = Boolean(anchorElFiltersMenu);
  const handleFiltersMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElFiltersMenu(event.currentTarget);
  };

  const handleCloseFiltersMenu = () => {
    setAnchorElFiltersMenu(null);
  };

  const handleSortFilter = (value: string) => {
    const [key] = value.split(":");
    const existingDirection = filtersRedux.sort?.[key];
    const newDirection = existingDirection === "desc" ? "asc" : "desc";

    const updatedSort = {
      ...filtersRedux.sort,
      [key]: newDirection
    };

    dispatch(
      setFilters({
        sort: updatedSort
      })
    );
    setFiltersDropdown((prev: FilterObjectType) => ({
      ...prev,
      sort: updatedSort
    }));
  };

  const handleRemoveAllSorts = () => {
    dispatch(setFilters((({ sort, ...rest }) => rest)(filters)));
    // @ts-expect-error: not exist
    setFiltersDropdown((({ sort, ...rest }) => rest)(filtersDropdown));
  };

  const sortFiltersApplied = Object.keys(filtersRedux || {}).includes("sort");

  const removeByOne = (obj: FilterObjectType, key: string) => {
    if (!obj.sort) {
      return { ...obj };
    }
    const { [key]: _, ...remainingSort } = obj.sort;

    if (Object.keys(remainingSort).length === 0) {
      const { sort, ...remaining } = obj;
      return { ...remaining };
    }
    return {
      ...obj,
      sort: { ...remainingSort }
    };
  };

  const handleRemoveOne = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => {
    e.stopPropagation();
    const updatedFilters = removeByOne(filtersRedux, key);
    dispatch(setFilters({ ...updatedFilters }));
    setFiltersDropdown({ ...updatedFilters });
  };

  const orderedSortFilterOptions = useMemo(() => {
    const { sort = {} } = filtersDropdown || {};
    return [...sortFilterOptions].sort((a, b) => {
      const priorityA = sort[a.value] ? 0 : 1;
      const priorityB = sort[b.value] ? 0 : 1;

      if (priorityA !== priorityB) return priorityA - priorityB;

      if (sort[a.value] && sort[b.value]) {
        return (
          Object.keys(sort).indexOf(a.value) -
          Object.keys(sort).indexOf(b.value)
        );
      }

      return 0;
    });
  }, [filtersDropdown, sortFilterOptions]);

  return (
    <Grid
      container
      alignItems="center" /* className="py-1 px-6 bg-default-white" */
      className={`py-1 bg-default-white ${withPadding && "px-6"}`}
    >
      <Grid item xs={4} py={1}>
        <Box gap={1} className="flex items-center">
          <Typography
            component="span"
            className="font-sans text-lg font-semibold text-gray-10 normal-case"
          >
            {label}
          </Typography>
          {numberOfResults && !loading && (
            <Badge message={numberOfResults} variant="neutral" type="Subtle" />
          )}
          {saveFilters && (
            <Box className="py-2">
              <Button
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  handleFiltersMenu(event)
                }
                message="Save search"
                size="xs"
                icon={<SaveRoundedIcon sx={{ fontSize: "16px" }} />}
              />
            </Box>
          )}

          <Menu
            classes={{
              paper: "mt-2 bg-default-white rounded-md"
            }}
            sx={{
              "& .MuiMenu-paper": {
                boxShadow: "0px 2px 2px 1px rgb(80 80 80 / 7%)"
              }
            }}
            anchorEl={anchorElFiltersMenu}
            open={openFiltersMenu}
            onClose={handleCloseFiltersMenu}
            onClick={handleCloseFiltersMenu}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              classes={{
                root: "font-sans font-medium text-sm px-4 py-2 hover:bg-primary-1 hover:text-primary-8 cursor-pointer"
              }}
              onClick={() => {
                dispatch(setSaveFiltersModal(true));
              }}
              disabled={_.isEmpty(filters)}
            >
              <Typography
                component="span"
                className="font-sans font-medium text-sm"
              >
                Save Search
              </Typography>
            </MenuItem>
            <MenuItem
              classes={{
                root: "font-sans font-medium text-sm px-4 py-2 hover:bg-primary-1 hover:text-primary-8 cursor-pointer"
              }}
              onClick={() => {
                dispatch(setViewSavedFiltersModal(true));
              }}
            >
              <Typography
                component="span"
                className="font-sans font-medium text-sm"
              >
                View Saved Searches
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Grid>

      <Grid item xs py={1}>
        <Grid
          container
          justifyContent="flex-end"
          gap={0.5}
          className="flex items-center"
        >
          <ThemeProvider theme={themePagination}>
            {showPagination && !loading && (
              <TablePagination
                sx={{
                  "& .MuiTablePagination-selectLabel": {
                    fontWeight: 500,
                    fontSize: "0.75rem",
                    lineHeight: "1rem"
                  },
                  "& .MuiTablePagination-displayedRows": {
                    fontWeight: 500,
                    fontSize: "0.75rem",
                    lineHeight: "1rem"
                  },
                  "& .MuiTablePagination-select": {
                    fontWeight: 500,
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                    display: "flex",
                    alignItems: "end"
                  },
                  "& .MuiTablePagination-menuItem": {
                    fontFamily: "Noto Sans, sans-serif !important",
                    fontWeight: 500,
                    fontSize: "1px",
                    lineHeight: "1rem"
                  }
                }}
                slotProps={{
                  select: {
                    sx: {
                      ".MuiMenuItem-root": {
                        fontSize: "0.75rem" // Adjust font size as needed
                      }
                    }
                  }
                }}
                component="div"
                count={numberOfResults}
                page={currentPage - 1}
                onPageChange={changeInnerPage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            )}
          </ThemeProvider>
          {download && canDownloadReports && (
            <Grid item>
              <Download
                data={dataTable}
                transform={transform}
                buttonSize="sm"
                label="Download"
              />
            </Grid>
          )}
          {shareButton && isAuthenticated && (
            <Grid item>
              <Button
                id="share"
                message="Share"
                variant="secondary"
                size="sm"
                onClick={shareAction}
              />
            </Grid>
          )}
          {withFilters && (
            <Grid item>
              <Box display="flex" alignItems="center" gap={1}>
                {withSearch && <SearchInput placeholder="Search..." />}
                {/* <Button
                id="advanced-search"
                  message="Advanced Search"
                  variant="secondary"
                  size="sm"
                  onClick={toggleFiltersVisible}
                /> */}
                <IconButton
                  id="advanced-search"
                  icon={
                    filtersVisible ? (
                      <IconArrowRight width={28} height={28} />
                    ) : (
                      <IconFilter width={28} height={28} />
                    )
                  }
                  size="sm"
                  variant="secondary"
                  onClick={toggleFiltersVisible}
                />
              </Box>
            </Grid>
          )}

          {/* End Pagination */}

          {/* Start Export */}
          {/*           <Grid item>
            <Button
            id="export"
              message={
                <Box display="flex" alignItems="center">
                  <Box className="mr-1">
                    <IconDownload width={18} height={20} />
                  </Box>
                  <Box>
                    <Typography
                      component="span"
                      className="normal-case font-sans text-sm font-semibold text-default-white"
                    >
                      Export
                    </Typography>
                  </Box>
                </Box>
              }
              variant="primary"
              size="sm"
            />
          </Grid> */}
          {/* End Export */}
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        gap={0.5}
        className="flex items-center"
      >
        {sortingFilters && (
          <>
            <Button
              id="filter-by-score"
              variant={sortFiltersApplied ? "primary" : "subtle"}
              message="Sort by score"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                setAnchorEl(e.currentTarget)
              }
              icon={
                sortFiltersApplied ? (
                  <FilterListRoundedIcon
                    sx={{ stroke: "transparent" }}
                    className="text-lg"
                  />
                ) : (
                  <FilterListOffRoundedIcon className="text-lg" />
                )
              }
            />
            <Menu
              classes={{
                paper: "mt-2 bg-default-white rounded-md shadow-md w-52"
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              autoFocus={false}
            >
              {orderedSortFilterOptions.map((option) => {
                // Get the current sort direction for the option if it exists
                const currentSort = filtersRedux?.sort?.[option.value];
                const arrowIcon =
                  currentSort === "asc" ? (
                    <ArrowUpwardRoundedIcon className="text-sm" />
                  ) : currentSort === "desc" ? (
                    <ArrowDownwardRoundedIcon className="text-sm" />
                  ) : null;

                return (
                  <MenuItem
                    className={`flex items-center justify-between  ${
                      arrowIcon && "bg-primary-2"
                    }`}
                    key={option.name}
                    id={option.value}
                    classes={{
                      root: "font-sans font-medium text-sm px-4 py-2 hover:bg-primary-1 hover:text-primary-8 cursor-pointer"
                    }}
                    onClick={() => handleSortFilter(option.value)}
                  >
                    {option.name}
                    <div className="flex items-center gap-2">
                      {arrowIcon && (
                        <span className="text-primary-8">{arrowIcon}</span>
                      )}
                      {arrowIcon && (
                        <Box
                          className="text-gray-4"
                          onClick={(e) => handleRemoveOne(e, option.value)}
                        >
                          <CloseRoundedIcon className="text-xs text-gray-4" />
                        </Box>
                      )}
                    </div>
                  </MenuItem>
                );
              })}
              <Box className="cursor-pointer" onClick={handleRemoveAllSorts}>
                <p className="p-4 pb-0 text-xs text-right text-primary-8 font-semibold">
                  Clear all
                </p>
              </Box>
            </Menu>
          </>
        )}
        {!_.isEmpty(optionsDropdown) && (
          <Box width={390}>
            {/*  <Select
              options={optionsDropdown}
              selectLabel="Offices"
              value={valueOffice}
              onChange={(e: any) => setValueOffice(e.target.value)}
                      /> */}
            <Autocomplete
              multiple
              id="Offices"
              options={optionsDropdown}
              disableCloseOnSelect
              disableListWrap
              size="small"
              PopperComponent={StyledPopper}
              ListboxComponent={ListboxComponent}
              renderOption={(renderProps, option, state) =>
                [renderProps, option, state.index] as React.ReactNode
              }
              onChange={(e, value) => {
                setValueOffice(value);
              }}
              classes={{
                option:
                  "h-[29px] min-h-[29px] p-2 font-sans text-sm font-medium text-gray-10 hover:text-primary-8 hover:bg-primary-1",
                tag: "h-[24px] min-h-[24px] p-2 font-sans text-sm font-medium text-gray-10 hover:text-primary-8 hover:bg-primary-1"
              }}
              value={valueOffice}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Select Office Locations"
                  sx={{
                    "& .MuiInputBase-input": {
                      padding: "7px 12px",
                      fontSize: "14px",
                      fontFamily: "Noto Sans, sans-serif",
                      lineHeight: "1.25rem",
                      color: "#1a202c",
                      backgroundColor: "#fff"
                    },
                    "& .MuiInputBase-root": {
                      border: `none`
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: colors.gray[4],
                        borderRadius: "0.375rem"
                      },
                      "&:hover fieldset": {
                        borderColor: colors.gray[5]
                      },
                      "&.Mui-focused fieldset": {
                        borderWidth: "1px",
                        borderColor: colors.primary[4]
                      },
                      "&.Mui-disabled fieldset": {
                        borderColor: colors.gray[4],
                        backgroundColor: colors.gray[1]
                      },
                      "&.Mui-error fieldset": {
                        borderColor: colors.default.error[7]
                      },
                      "&.Mui-error:hover fieldset": {
                        borderColor: colors.default.error[7]
                      },
                      "&.Mui-error.Mui-focused fieldset": {
                        borderColor: colors.default.error[7]
                      },
                      "&.Mui-error.Mui-disabled fieldset": {
                        borderColor: colors.gray[4],
                        backgroundColor: colors.gray[1]
                      },
                      "&.MuiInputLabel-root": {
                        color: colors.primary[4]
                      }
                    }
                  }}
                />
              )}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default ResultsBar;
